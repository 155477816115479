import { Marker, LatLngBoundsLiteral } from "leaflet";
import { GeoPoint, LatLonPoint, ONE_LATITUDE_IN_METERS, calculateLatitudeDeltas, calculateLongitudeDeltas, parallelDistanceMeters } from "./GeoPoint"
import { Length, meters } from "./Length"
import { Bearing, NORTH_EAST, NORTH_WEST, NamedBearing, SOUTH_EAST, SOUTH_WEST } from "./Bearing";


export abstract class GeoShape {
    abstract get northLatitude(): number
    abstract get southLatitude(): number
    abstract get westLongitude(): number
    abstract get eastLongitude(): number

    abstract get center(): GeoPoint

    get northEastBound(): GeoPoint {
        return new LatLonPoint(this.northLatitude, this.eastLongitude);
    }

    get southWestBound(): GeoPoint {
        return new LatLonPoint(this.southLatitude, this.westLongitude);
    }

    get northWestBound(): GeoPoint {
        return new LatLonPoint(this.northLatitude, this.westLongitude);
    }

    get southEastBound(): GeoPoint {
        return new LatLonPoint(this.southLatitude, this.eastLongitude);
    }
    
    get bounds(): EuclideanRectangle {
        return new EuclideanRectangle(this.northLatitude, this.southLatitude, this.westLongitude, this.eastLongitude)
    }
}


export class EuclideanRectangle extends GeoShape {
    private _width: Length | undefined;
    private _height: Length | undefined;

    constructor(
        readonly southLatitude: number,
        readonly westLongitude: number,
        readonly northLatitude: number,
        readonly eastLongitude: number,
    ) {
        super()
    }

    get center(): GeoPoint {
        return this.northEastBound.midpointTo(this.southWestBound);
    }

    get bounds(): EuclideanRectangle {
        return this
    }

    get northEastBound(): GeoPoint {
        return new LatLonPoint(this.northLatitude, this.eastLongitude);
    }

    get southWestBound(): GeoPoint {
        return new LatLonPoint(this.southLatitude, this.westLongitude);
    }

    getCorner(intercardinalDirection: NamedBearing): GeoPoint {
        if (intercardinalDirection.degrees == NORTH_EAST.degrees) {
            return this.northEastBound;
        } else if (intercardinalDirection.degrees == NORTH_WEST.degrees) {
            return this.northWestBound;
        } else if (intercardinalDirection.degrees == SOUTH_WEST.degrees) {
            return this.southWestBound;
        } else if (intercardinalDirection.degrees == SOUTH_EAST.degrees) {
            return this.southEastBound;
        } else {
            throw new Error(`Invalid intercardinal direction: ${intercardinalDirection}`);
        }
    }

    withCorner(intercardinalDirection: NamedBearing, corner: GeoPoint): EuclideanRectangle {
        const fixedCorner: GeoPoint = this.getCorner(intercardinalDirection.flipped)
        return EuclideanRectangle.fromCorners(fixedCorner, corner);
    }

    get width(): Length {
        if (this._width === undefined) {
            const longitude_delta = calculateLongitudeDeltas(this.westLongitude, this.eastLongitude);
            this._width = meters(parallelDistanceMeters(longitude_delta, this.center.latitude));
        }
        return this._width;
    }

    get height(): Length {
        if (this._height === undefined) {
            const latitude_delta = calculateLatitudeDeltas(this.southLatitude, this.northLatitude);
            this._height = meters(latitude_delta * ONE_LATITUDE_IN_METERS);
        }
        return this._height;
    }

    toData(): Record<string, any> {
        return {
            type: "rectangle",
            north_east: this.northEastBound.toData(),
            south_west: this.southWestBound.toData(),
        };
    }

    static fromData(data: Record<string, any>): EuclideanRectangle {
        if (data.type !== "rectangle") {
            throw new Error("Invalid data type for EuclideanRectangle");
        }
        const { north_east, south_west } = data;
        const south_latitude = south_west.latitude;
        const west_longitude = south_west.longitude;
        const north_latitude = north_east.latitude;
        const east_longitude = north_east.longitude;
        return new EuclideanRectangle(south_latitude, west_longitude, north_latitude, east_longitude);
    }

    static boundsFromCenter(center: GeoPoint, width: Length, height: Length): [GeoPoint, GeoPoint] {
        const metersToLatitudeFactor = 1 / ONE_LATITUDE_IN_METERS;
        const latitudeDelta = height.meters * metersToLatitudeFactor / 2;
        const minLatitude = center.latitude - latitudeDelta;
        const maxLatitude = center.latitude + latitudeDelta;

        const metersToLongitudeFactor = 1 / parallelDistanceMeters(1, center.latitude);
        const longitudeDelta = width.meters * metersToLongitudeFactor / 2;
        const minLongitude = center.longitude - longitudeDelta;
        const maxLongitude = center.longitude + longitudeDelta;

        return [new LatLonPoint(minLatitude, minLongitude), new LatLonPoint(maxLatitude, maxLongitude)];
    }

    atCenter(center: GeoPoint): EuclideanRectangle {
        return EuclideanRectangle.fromCenter(center, this.width, this.height);
    }

    static fromCorners(corner1: GeoPoint, corner2: GeoPoint): EuclideanRectangle {
        const southLatitude = Math.min(corner1.latitude, corner2.latitude);
        const northLatitude = Math.max(corner1.latitude, corner2.latitude);
        const westLongitude = Math.min(corner1.longitude, corner2.longitude);
        const eastLongitude = Math.max(corner1.longitude, corner2.longitude);
        return new EuclideanRectangle(southLatitude, westLongitude, northLatitude, eastLongitude);
    }

    static fromCenter(center: GeoPoint, width: Length, height: Length | null = null): EuclideanRectangle {
        if (height === null) {
            height = width;
        }
        const [ south_west, north_east ] = EuclideanRectangle.boundsFromCenter(center, width, height);
        return EuclideanRectangle.fromCorners(south_west, north_east);
    }
    
    get leafletBoundsLiteral(): LatLngBoundsLiteral {
        return [
            [this.southWestBound.latitude, this.southWestBound.longitude],
            [this.northEastBound.latitude, this.northEastBound.longitude]
        ]
    }
    
    get leafletBounds(): any {
        return this.leafletBoundsLiteral
    }
}


export class Circle extends GeoShape {
    constructor(
        readonly center: GeoPoint, 
        readonly radius: Length
    ) {
        super()
    }

    get northLatitude(): number {
        return this.center.latitude + this.radius.meters / ONE_LATITUDE_IN_METERS
    }

    get southLatitude(): number {
        return this.center.latitude - this.radius.meters / ONE_LATITUDE_IN_METERS
    }

    get eastLongitude(): number {
        let lonFactor = parallelDistanceMeters(1, this.center.latitude)
        return this.center.longitude + this.radius.meters / lonFactor
    }

    get westLongitude(): number {
        let lonFactor = parallelDistanceMeters(1, this.center.latitude)
        return this.center.longitude - this.radius.meters / lonFactor
    }

    toData(): { type: string, center: Record<string, any>, radius: string } {
        return {
            type: "circle",
            center: this.center.toData(),
            radius: this.radius.toString()
        };
    }

    static fromData(data: Record<string, any>): Circle {
        if (data.type !== "circle") {
            throw new Error("Invalid data type for Circle");
        }
        const { center, radius } = data;
        return new Circle(GeoPoint.fromData(center), Length.parse(radius));
    }
}


