import { LocalUser, User } from "./User.js";

export class Header {
    readonly sessionUser?: User;
    readonly loginButton: HTMLAnchorElement;
    readonly signupButton: HTMLAnchorElement;
    readonly logoutButton: HTMLAnchorElement;
    readonly usernameElement?: HTMLAnchorElement;

    constructor(readonly headerElement: HTMLElement) {
        this.sessionUser = window.sessionUser;

        const headerLeft = document.createElement('div');
        headerLeft.className = 'header-left';
        const headerRight = document.createElement('div');
        headerRight.className = 'header-right';

        let topLeftText: string;
        let topLeftId: string;
        if (this.sessionUser !== undefined) {
            topLeftText = this.sessionUser.username
            topLeftId = 'username'
            const fillUsernameElements = document.getElementsByClassName('fill-username');
            for (let i = 0; i < fillUsernameElements.length; i++) {
                const element = fillUsernameElements[i] as HTMLElement;
                element.innerHTML = this.sessionUser.username;
            }
        } else {
            topLeftText = 'GeoGame'
            topLeftId = 'title'
        }

        this.usernameElement = this.createButton(topLeftId, '/', topLeftText);
        headerLeft.appendChild(this.usernameElement);

        this.loginButton = this.createButton('loginButton', '/login', 'Login');
        this.signupButton = this.createButton('signupButton', '/sign_up', 'Sign Up');
        this.logoutButton = this.createButton('logoutButton', '/logout', 'Logout');
        
        headerRight.appendChild(this.loginButton);
        headerRight.appendChild(this.signupButton);
        headerRight.appendChild(this.logoutButton);

        this.headerElement.appendChild(headerLeft);
        this.headerElement.appendChild(headerRight);

        const signedInDisplay = this.isSignedIn ? 'none' : 'inline';
        const signedOutDisplay = this.isSignedIn ? 'inline' : 'none';

        this.loginButton.style.display = signedInDisplay;
        this.signupButton.style.display = signedInDisplay;
        this.logoutButton.style.display = signedOutDisplay;

    }

    private createButton(id: string, href: string, text: string): HTMLAnchorElement {
        const button = document.createElement('a');
        button.id = id;
        button.className = 'button';
        button.href = href;
        button.textContent = text;
        return button;
    }

    get isSignedIn(): boolean {
        return this.sessionUser !== undefined;
    }
}

declare global {
    interface Window {
        sessionUser?: LocalUser;
        playerId?: string;
        worldName?: string;
    }
}

export class Page {
    readonly sessionUser?: LocalUser;
    readonly header?: Header;

    constructor() {
        this.sessionUser = window.sessionUser
        const headerElements = document.getElementsByTagName('header');
        if (headerElements.length > 0) {
            this.header = new Header(headerElements[0])
        }

    }

    get isSignedIn(): boolean {
        return this.sessionUser !== undefined;
    }
}