
export function areApproximately(first: number, second: number, epsilon: number = 0.000000001): boolean {
    return Math.abs(first - second) < epsilon;
}


export function toRadians(degrees: number): number {
    return degrees * Math.PI / 180;
}

export function toDegrees(radians: number): number {
    return radians * 180 / Math.PI;
}


export async function getJson(url: string, followRedirects: boolean = true): Promise<any> {
    return fetch(url, {
        method: "GET",
        headers: { 'Content-Type': 'application/json' },
    }).then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        if (response.redirected && followRedirects) {
            window.location.href = response.url;
            return Promise.resolve({})
        }
        return response.json();
    });
}

export async function postJson(url: string, data: object): Promise<Response> {
    return fetch(url, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
}

export async function postAndReceiveJson(url: string, data: object, followRedirects: boolean = true): Promise<any> {
    return postJson(url, data).then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        if (response.redirected && followRedirects) {
            window.location.href = response.url;
        }
        return response.json();
    });
}

export async function fetchUserNames(): Promise<string[]> {
    return getJson("/usernames")
}

export function isValidEmail(email: string): boolean {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function validateInput(input: HTMLInputElement, isValid: (value: string)=>boolean) {
    input.addEventListener('input', function() {
        if (isValid(input.value)) {
            input.classList.remove('error');
        } else {
            input.classList.add('error');
        }
    });
}

export function appendInvisibleFormValue(form: HTMLFormElement, name: string, value: string | null = null): HTMLInputElement {
    const hiddenInput = document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.name = name;
    if (value !== null) {
        hiddenInput.value = value;
    }
    form.appendChild(hiddenInput)
    return hiddenInput
}

export function getCoordinates(timeout: number = 10000): Promise<GeolocationCoordinates> {
    return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
            reject(new Error("Geolocation is not supported by this browser."));
        } else {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve(position.coords);
                },
                (error) => {
                    reject(error);
                },
                {
                    enableHighAccuracy: true,
                    timeout: timeout,
                    maximumAge: 0
                }
            );
        }
    });
}

export function createDataList(listId: string, options: string[]): HTMLDataListElement {
    const dataList = document.createElement('datalist');
    dataList.setAttribute('id', listId);
    options.forEach(ooption => {
        const option = document.createElement('option');
        option.value = ooption;
        dataList.appendChild(option);
    });
    return dataList
}

export function formatTime(date: Date): string {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
}

export function formatDateTime(date: Date, omitDateIfToday: boolean = false): string {
    if (omitDateIfToday && date.toDateString() === new Date().toDateString()) {
        return formatTime(date);
    }
    let offset = date.getTimezoneOffset() * 60000;
    const newDate = new Date(date.getTime() - offset);
    const isoDate = newDate.toISOString().replace("T", " ")
    return isoDate.split(":").slice(0, -1).join(":");
}

export function isDebug() {
    return window.location.hostname === 'localhost' || window.location.hostname.startsWith('127.');
}

export function conditionalClass(element: HTMLElement, className: string, condition: boolean) {
    if (condition) {
        element.classList.add(className);
    } else {
        element.classList.remove(className);
    }
}
