import { ButtonRow } from "./ButtonRow"
import { DataConverter } from "./DataConverters";
import { Milliseconds, Seconds, ServerTime, Time, TimeSyncer } from "./Time";


export enum EffectAlignment {
    Negative = "negative",
    Mixed = "mixed",
    Positive = "positive"
}

export namespace EffectAlignment {
    export function parse(value: string): EffectAlignment {
        if (Object.values(EffectAlignment).includes(value as EffectAlignment)) {
            return value as EffectAlignment;
        }
        throw new Error(`Invalid EffectAlignment value: ${value}`);
    }
}


export class EffectType {
    constructor(readonly webId: string, readonly name: string, readonly description: string, readonly alignment: EffectAlignment) {

    }

    static fromData(data: any): EffectType {
        return new EffectType(
            data["web_id"],
            data["name"],
            data["description"],
            EffectAlignment.parse(data["alignment"])
        );
    }
}

export class Effect {
    constructor(readonly effectId: string, readonly effectType: EffectType, public until?: ServerTime) {

    }

    get duration(): Time | undefined {
        if (this.until === undefined || this.untilTime === undefined) {
            return undefined
        }
        const now = new Date()
        if (this.untilTime < now) {
            return new Milliseconds(0)
        }
        return new Milliseconds(this.untilTime.getTime() - now.getTime())
    }

    get untilTime(): Date | undefined {
        return this.until?.localTime
    }

    static fromData(data: any, effectTypeDict: { [key: string]: EffectType; }, syncer: TimeSyncer): Effect {
        const effectType = effectTypeDict[data["effect_type_id"]];
        if (!effectType) {
            throw new Error(`EffectType with id ${data["effect_type_id"]} not found in the provided dictionary`);
        }
        const until = parseUntil(data["until"], syncer)
        return new Effect(
            data["effect_id"],
            effectType,
            until
        );
    }
}


export class EffectNode {
    private intervalId?: NodeJS.Timeout
    private timeoutId?: NodeJS.Timeout

    constructor(readonly effect: Effect, readonly htmlButton: HTMLButtonElement, readonly label: HTMLSpanElement, readonly syncer: TimeSyncer) 
    {
        this.updateLabel();
        this.startLabelUpdate();
    }

    startLabelUpdate() {
        const duration = this.effect.duration
        if (duration !== undefined) {
            const msToNextSecond = duration.milliseconds % 1000;
            this.clearTimeouts();
            this.timeoutId = setTimeout(() => {
                this.startInterval();
                this.updateLabel();
            }, msToNextSecond);
        }
    }

    startInterval() {
        if (this.intervalId !== undefined) {
            clearInterval(this.intervalId);
        }
        this.intervalId = setInterval(() => {
            const duration = this.effect.duration
            if (duration === undefined || duration.seconds <= 0) {
                this.clearTimeouts();
            }
            this.updateLabel();
        }, 1000);
    }

    clearTimeouts() {
        if (this.timeoutId !== undefined) {
            clearTimeout(this.timeoutId);
        }
        if (this.intervalId !== undefined) {
            clearInterval(this.intervalId);
        }
    }

    updateLabel() {
        const duration = this.effect.duration
        if (duration !== undefined) {
            this.label.innerText = duration.roundedSeconds().toString()
        } else {
            this.label.innerText = ""
        }
    }

    set until(serverTime: ServerTime | undefined) {
        if (this.effect.until?.unparsedServerTime !== serverTime?.unparsedServerTime) {
            this.effect.until = serverTime
            this.startLabelUpdate()
        }
    }
}

function parseUntil(data: any, syncer: TimeSyncer): ServerTime | undefined {
    if (data === "unknown") {
        return undefined
    }
    return new ServerTime(data["end_at"], syncer)
}

export class EffectPanel extends ButtonRow {
    private effectTypesById: { [id: string]: EffectType} = {}
    private effects: DataConverter<EffectNode>

    constructor(container: HTMLElement, readonly effectTypes: EffectType[], private syncer: TimeSyncer) {
        super(container);
        this.effects = new DataConverter((data: any) => this.addEffectFromData(data))
        this.effects.onUpdateListeners.push((effectNode: EffectNode, data: any) => this.onEffectUpdate(effectNode, data))
        this.effects.onRemoveListeners.push((node: EffectNode) => this.removeButton(node.htmlButton))
        effectTypes.forEach(effectType => this.effectTypesById[effectType.webId] = effectType)
    }

    addEffectFromData(data: any): EffectNode {
        const effect: Effect = Effect.fromData(data, this.effectTypesById, this.syncer)
        const button = this.addButton("", () => this.onEffectClick(effect))
        button.classList.add(effect.effectType.webId)
        button.classList.add(effect.effectType.alignment)

        const labelElement = document.createElement("span") as HTMLSpanElement
        labelElement.classList.add("label")
        button.appendChild(labelElement)

        const effectNode = new EffectNode(effect, button, labelElement, this.syncer);

        return effectNode
    }

    onEffectUpdate(effectNode: EffectNode, data: any) {
        const until = parseUntil(data["until"], this.syncer);
        effectNode.until = until;
    }

    onEffectClick(effect: Effect) {
        // TODO: show effect details
    }

    update(data: any) {
        this.effects.update(data)
    }
}