import { Socket } from "socket.io-client";

export abstract class StochasticTime {
    abstract get seconds(): number
    abstract get varianceSeconds(): number
}

export abstract class Time extends StochasticTime {
    abstract get seconds(): number
    abstract get milliseconds(): number
    get varianceSeconds(): 0 {
        return 0
    }

    public static parse(to_parse: string) : Time {
        to_parse = to_parse.trim()
        if (to_parse.endsWith("s"))
            return new Seconds(parseFloat(to_parse.slice(0, -1)));
        if (to_parse.endsWith("min"))
            return new Minutes(parseFloat(to_parse.slice(0, -3)));
        throw new Error("Invalid time format. Time must end with 's' or 'min'.");
    }

    abstract multiplied(scalar: number): Time;

    roundedSeconds(): Seconds {
        return new Seconds(Math.round(this.seconds));
    }

    abstract subtractSeconds(seconds: number): Time;
}


export class Seconds extends Time {
    constructor(readonly seconds: number) {
        super();
    }

    get milliseconds(): number {
        return this.seconds * 1000;
    }

    toString(): string {
        return `${this.seconds}s`;
    }

    multiplied(scalar: number): Seconds {
        return new Seconds(this.seconds * scalar);
    }

    subtractSeconds(seconds: number): Time {
        return new Seconds(this.seconds - seconds);
    }
}

export class Minutes extends Time {
    constructor(readonly minutes: number) {
        super();
    }

    get seconds(): number {
        return this.minutes * 60;
    }

    get milliseconds(): number {
        return this.seconds * 1000;
    }

    toString(): string {
        return `${this.minutes}min`;
    }

    multiplied(scalar: number): Minutes {
        return new Minutes(this.minutes * scalar);
    }

    subtractSeconds(seconds: number): Time {
        return new Seconds(this.seconds - seconds);
    }
}

export class Milliseconds extends Time {
    constructor(readonly milliseconds: number) {
        super();
    }

    get seconds(): number {
        return this.milliseconds / 1000;
    }

    toString(): string {
        return `${this.milliseconds}ms`;
    }

    multiplied(scalar: number): Milliseconds {
        return new Milliseconds(this.milliseconds * scalar);
    }

    subtractSeconds(seconds: number): Time {
        return new Milliseconds(this.milliseconds - seconds * 1000);
    }
}


export class ServerTime {
    private _localTime?: Date;
    constructor(readonly unparsedServerTime: string, readonly syncer: TimeSyncer) {
        this._localTime = syncer.parseToLocalTime(unparsedServerTime);
    }

    get localTime(): Date | undefined {
        return this._localTime;
    }
}


export class TimeSyncer {
    private lastLatencyMilliseconds?: number;
    private measureServerOffsetsMilliseconds: number[] = [];
    private writeIndex: number = 0;

    constructor(private socket: Socket, private maxMeasurements: number = 10) {
    }

    sync() {
        const now = new Date()
        this.socket.emit("ping", "ping", (response: string) => {
            this.onTimeSync(now, response)
        });
    }

    onTimeSync(sendTime: Date, unparsedServerTime: string) {
        const now = new Date()
        const serverTime = new Date(unparsedServerTime)
        const roundTripTime = now.getTime() - sendTime.getTime()
        this.lastLatencyMilliseconds = roundTripTime / 2
        const serverOffsetMilliseconds = serverTime.getTime() - now.getTime() + this.lastLatencyMilliseconds
        this.measureServerOffsetsMilliseconds[this.writeIndex] = serverOffsetMilliseconds
        this.writeIndex = (this.writeIndex + 1) % this.maxMeasurements
    }

    get averagedServerOffsetMilliseconds(): number | undefined {
        if (this.measureServerOffsetsMilliseconds.length === 0) {
            return undefined
        }
        return this.measureServerOffsetsMilliseconds.reduce((a, b) => a + b) / this.measureServerOffsetsMilliseconds.length
    }

    get estimatedServerTime(): Date | undefined{
        if (this.averagedServerOffsetMilliseconds === undefined) {
            return undefined
        }
        return new Date(new Date().getTime() + this.averagedServerOffsetMilliseconds)
    }

    parseToLocalTime(unparsedServerTime: string): Date | undefined{
        if (this.averagedServerOffsetMilliseconds === undefined) {
            return undefined
        }
        const serverTime = new Date(unparsedServerTime)
        return new Date(serverTime.getTime() - this.averagedServerOffsetMilliseconds)
    }
}
