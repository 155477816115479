
export class ButtonRow {
    constructor(readonly container: HTMLElement) {

    }

    addButton(label: string, action: () => void): HTMLButtonElement {
        const button = document.createElement('button');
        button.textContent = label;
        button.addEventListener('click', action);
        this.container.appendChild(button);

        return button
    }

    removeButton(button: HTMLButtonElement): void {
        this.container.removeChild(button);
    }
}