import { Bearing } from "./Bearing";
import { GeoPoint } from "./GeoPoint";

export interface Type<T> {
    isInstance(value: any): value is T;
    serverIdentifier: string,
    toData(value: T): any
    fromData(data: any): T
}

export interface ToDataType {
    toData(): any
}

class ClassType<T extends ToDataType> implements Type<T> {
    constructor(
        readonly ofClass: any,
        readonly serverIdentifier: string,
        private fromDataFunction: (data: any) => T
    ) {}

    isInstance(value: any): value is T {
        return value instanceof this.ofClass;
    }

    toData(value: T): any {
        return value.toData()
    }

    fromData(data: any): T {
        return this.fromDataFunction(data)
    }
}

export const STRING_TYPE: Type<string> = {
    isInstance: (value: any): value is string => typeof value === 'string',
    serverIdentifier: "str",
    toData: (value: string): any => value,
    fromData: (data: any): string => data.toString()
};

export const NUMBER_TYPE: Type<number> = {
    isInstance: (value: any): value is number => typeof value === 'number',
    serverIdentifier: "float",
    toData: (value: number): any => value,
    fromData: (data: any): number => typeof data === 'number' ? data : parseFloat(data)
};

export const INTEGER_TYPE: Type<number> = {
    isInstance: (value: any): value is number => typeof value === 'number' && Number.isInteger(value),
    serverIdentifier: "int",
    toData: (value: number): any => value,
    fromData: (data: any): number => typeof data === 'number' ? data : parseInt(data)
};

export const BOOLEAN_TYPE: Type<boolean> = {
    isInstance: (value: any): value is boolean => typeof value === 'boolean',
    serverIdentifier: "bool",
    toData: (value: boolean): any => value,
    fromData: (data: any): boolean => typeof data === 'boolean' ? data : (data === "true")
};

export const GEOPOINT_TYPE: Type<GeoPoint> = new ClassType(GeoPoint, "GeoPoint", (data) => GeoPoint.fromData(data));

export const BEARING_TYPE: Type<Bearing> = new ClassType(Bearing, "Bearing", (data) => Bearing.fromData(data))

export const ALL_TYPES: Type<any>[] = [
    STRING_TYPE,
    NUMBER_TYPE,
    INTEGER_TYPE,
    BOOLEAN_TYPE,
    GEOPOINT_TYPE,
    BEARING_TYPE
]


export const TYPES_BY_SERVER_NAME: { [key: string]: Type<any> } = {}
for (const type of ALL_TYPES) {
    TYPES_BY_SERVER_NAME[type.serverIdentifier] = type
}

export function parseType(typeStr: string): Type<any> {
    return TYPES_BY_SERVER_NAME[typeStr]
    // throw new Error(`Type ${typeStr} not recognized.`);
}