
class AngleBase {
    radians: number;
    degrees: number;

    constructor(degrees?: number, radians?: number) {
        this.degrees = degrees ?? (radians! * 180 / Math.PI);
        this.radians = radians ?? (degrees! * Math.PI / 180);
    }
}

export class Bearing extends AngleBase {
    constructor(degrees?: number, radians?: number) {
        super(degrees, radians);
    }

    toString(): string {
        const closest = NAMED_BEARINGS.reduce((prev, curr) =>
            Math.abs(curr.degrees - this.degrees) < Math.abs(prev.degrees - this.degrees) ? curr : prev);
        if (Math.abs(closest.degrees - this.degrees) < 1) {
            return `~${closest.toString()}`;
        } else {
            return `${this.degrees.toFixed(2)}°`;
        }
    }

    flip(): Bearing {
        return new Bearing(undefined, this.radians + Math.PI);
    }

    static approximate(degrees?: number, radians?: number): Bearing {
        if (degrees !== undefined) {
            const roundedDegrees = Math.round(degrees) % 360;
            return INT_DEGREES_BEARINGS[roundedDegrees];
        } else if (radians !== undefined) {
            const roundedDegrees = Math.round((radians * 180 / Math.PI)) % 360;
            return INT_DEGREES_BEARINGS[roundedDegrees];
        } else {
            throw new Error("Must provide either radians or degrees");
        }
    }

    static getClosestNamedBearing(angleRadians?: number, angleDegrees?: number): Bearing {
        if (angleDegrees !== undefined) {
            return NAMED_BEARINGS.reduce((prev, curr) =>
                Math.abs(curr.degrees - angleDegrees) < Math.abs(prev.degrees - angleDegrees) ? curr : prev);
        } else if (angleRadians !== undefined) {
            return NAMED_BEARINGS.reduce((prev, curr) =>
                Math.abs(curr.radians - angleRadians) < Math.abs(prev.radians - angleRadians) ? curr : prev);
        } else {
            throw new Error("Must provide either angleRadians or angleDegrees");
        }
    }

    static random(): Bearing {
        return Bearing.approximate(Math.random() * 360);
    }

    toData(): any {
        return this.degrees
    }

    static fromData(data: any): Bearing {
        if (typeof data === 'number') {
            return new Bearing(data);
        } else {
            throw new Error("Invalid data type. Expected number.");
        }
    }
}

export class NamedBearing extends Bearing {
    name: string;

    constructor(name: string, degrees: number) {
        super(degrees);
        this.name = name;
        NAMED_BEARINGS_BY_DEGREES[degrees] = this;
    }

    toString(): string {
        return `${this.name}`;
    }

    flip(): NamedBearing {
        return NAMED_BEARINGS_BY_DEGREES[(this.degrees + 180) % 360];
    }

    get flipped(): NamedBearing {
        return NAMED_BEARINGS_BY_DEGREES[(this.degrees + 180) % 360];
    }
}

// Initialize bearings
export const NAMED_BEARINGS_BY_DEGREES: { [degree: number]: NamedBearing } = {};

export const NORTH = new NamedBearing("North", 0);
export const EAST = new NamedBearing("East", 90);
export const SOUTH = new NamedBearing("South", 180);
export const WEST = new NamedBearing("West", 270);

export const NORTH_EAST = new NamedBearing("North East", NORTH.degrees + 45);
export const SOUTH_EAST = new NamedBearing("South East", EAST.degrees + 45);
export const SOUTH_WEST = new NamedBearing("South West", SOUTH.degrees + 45);
export const NORTH_WEST = new NamedBearing("North West", WEST.degrees + 45);

export const NORTH_NORTH_EAST = new NamedBearing("North North East", NORTH.degrees + 22.5);
export const EAST_NORTH_EAST = new NamedBearing("East North East", NORTH_EAST.degrees + 22.5);
export const EAST_SOUTH_EAST = new NamedBearing("East South East", EAST.degrees + 22.5);
export const SOUTH_SOUTH_EAST = new NamedBearing("South South East", SOUTH_EAST.degrees + 22.5);
export const SOUTH_SOUTH_WEST = new NamedBearing("South South West", SOUTH.degrees + 22.5);
export const WEST_SOUTH_WEST = new NamedBearing("West South West", SOUTH_WEST.degrees + 22.5);
export const WEST_NORTH_WEST = new NamedBearing("West North West", WEST.degrees + 22.5);
export const NORTH_NORTH_WEST = new NamedBearing("North North West", NORTH_WEST.degrees + 22.5);

export const CARDINAL_BEARINGS: NamedBearing[] = [NORTH, EAST, SOUTH, WEST];
export const INTERCARDINAL_BEARINGS: NamedBearing[] = [NORTH_EAST, SOUTH_EAST, SOUTH_WEST, NORTH_WEST];
export const INTERMEDIATE_BEARINGS: NamedBearing[] = [
    NORTH_NORTH_EAST, EAST_NORTH_EAST, EAST_SOUTH_EAST, SOUTH_SOUTH_EAST,
    SOUTH_SOUTH_WEST, WEST_SOUTH_WEST, WEST_NORTH_WEST, NORTH_NORTH_WEST
];

export const NAMED_BEARINGS: Bearing[] = [...CARDINAL_BEARINGS, ...INTERCARDINAL_BEARINGS, ...INTERMEDIATE_BEARINGS].sort((a, b) => a.degrees - b.degrees);

export const INT_DEGREES_BEARINGS: Bearing[] = Array.from({ length: 360 }, (_, i) =>
    NAMED_BEARINGS.find(bearing => Math.abs(bearing.degrees - i) < 0.01) ?? new Bearing(i)
);
