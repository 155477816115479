import { areApproximately } from "./Utils";


export abstract class StochasticLength {
    abstract get meters(): number
    abstract get varianceMeters(): number
}


export abstract class Length extends StochasticLength {
    abstract get meters(): number
    abstract get kilometers(): number
    get varianceMeters(): 0 {
        return 0
    }

    public static parse(to_parse: string) : Length {
        to_parse = to_parse.trim()
        if (to_parse.endsWith("km"))
            return new Kilometers(parseFloat(to_parse.slice(0, -2)));
        if (to_parse.endsWith("m"))
            return new Meters(parseFloat(to_parse.slice(0, -1)));
        throw new Error("Invalid length format. Length must end with 'm' or 'km'.");
    }

    abstract multiplied(scalar: number): Length;

    abstract added(delta: Length): Length;

    roundedMeters(): Meters {
        return new Meters(Math.round(this.meters));
    }
}


export class StochasticMeters extends StochasticLength {
    constructor(readonly meters: number, readonly varianceMeters: number) {
        super();
    }

    toString(): string {
        return `${this.meters}±${this.varianceMeters}m`;
    }
}


export class Meters extends Length {
    constructor(readonly meters: number) {
        super();
    }

    get kilometers(): number {
        return this.meters / 1000;
    }

    toString(): string {
        return `${this.meters}m`;
    }

    multiplied(scalar: number): Meters {
        return new Meters(this.meters * scalar);
    }

    added(delta: Length): Length {
        return new Meters(this.meters + delta.meters);
    }
}

export class Kilometers extends Length {
    constructor(readonly kilometers: number) {
        super();
    }

    get meters(): number {
        return this.kilometers * 1000;
    }

    toString(): string {
        return `${this.kilometers}km`;
    }

    multiplied(scalar: number): Kilometers {
        return new Kilometers(this.kilometers * scalar);
    }

    added(delta: Length): Length {
        return new Kilometers(this.kilometers + delta.kilometers);
    }
}


export function stochasticMeters(value: number, variance?: number): StochasticLength {
    if (variance && !areApproximately(variance, 0)) {
        return new StochasticMeters(value, variance);
    } else {
        return new Meters(value);
    }
}

export function meters(value: number): Length {
    return new Meters(value);
}

