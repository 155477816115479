import { Parameter, ParameterAssignment } from "./Parameter";
import { Type } from "./Types";


class ValueValidityError extends Error {
    constructor(description: string) {
        super(description);
        Object.setPrototypeOf(this, ValueValidityError.prototype); // Fix for extending built-in types in TypeScript
    }
}

class ActionInputMissingValueError extends ValueValidityError {
    missingInputName: string;

    constructor(missingInputName: string, description?: string) {
        description = description || `Expected value ${missingInputName}.`;
        super(description);
        this.missingInputName = missingInputName;
        Object.setPrototypeOf(this, ActionInputMissingValueError.prototype);
    }
}

class ValueValidityTypeError extends ValueValidityError {
    invalidInput: any;
    expectedType: Type<any>;

    constructor(invalidInput: any, expectedType: Type<any>, description?: string) {
        description = description || `Expected type ${expectedType.serverIdentifier} as input. Got ${invalidInput} instead.`;
        super(description);
        this.invalidInput = invalidInput;
        this.expectedType = expectedType;
        Object.setPrototypeOf(this, ValueValidityTypeError.prototype);
    }
}

export class ActionType implements ActionType {
    webId: string;
    actionName: string;
    actionDescription: string;
    parameters: Parameter<any>[];

    constructor(webId: string, actionName: string, actionDescription: string, parameters: Parameter<any>[] = []) {
        this.webId = webId;
        this.actionName = actionName;
        this.actionDescription = actionDescription;
        this.parameters = parameters;
    }

    static fromData(data: any): ActionType {
        return new ActionType(
            data["web_id"],
            data["name"],
            data["description"],
            data["parameters"].map((p: Record<string, any>) => Parameter.fromData(p)) // Implement `Parameter.fromData` based on the structure of 'Parameter'
        );
    }
}

export interface Action {
    actionId: string
    actionType: ActionType
}
