import { Bearing, CARDINAL_BEARINGS, NORTH } from "./Bearing";
import { Length, Meters as Meters, StochasticLength, meters, stochasticMeters } from "./Length";
import { areApproximately, getCoordinates, isDebug, toDegrees, toRadians } from "./Utils";
import { EARTH_CIRCUMFERENCE_METERS, EARTH_RADIUS_METERS, TWO_PI } from "./Constants";
import { Popup } from "./Popup";


export interface GeoPointData {
    latitude: number;
    longitude: number;
    varianceMeters?: number;
}

export function parallelDistanceMeters(longitudeDelta: number, atLatitude: number): number {
    const radiansLongitude = toRadians(longitudeDelta);
    return EARTH_RADIUS_METERS * Math.cos(toRadians(atLatitude)) * radiansLongitude;
}

export function meridianDistanceMeters(latitudeDelta: number): number {
    const radiansLatitude = toRadians(latitudeDelta);
    return EARTH_RADIUS_METERS * radiansLatitude;
}

export function latLonDistanceMeters(fromLatitude: number, fromLongitude: number, toLatitude: number, toLongitude: number): number {
    const radiansLatitude = toRadians(toLatitude - fromLatitude);
    const radiansLongitude = toRadians(toLongitude - fromLongitude);
    const a = Math.sin(radiansLatitude / 2) ** 2 +
              Math.cos(toRadians(fromLatitude)) * Math.cos(toRadians(toLatitude)) *
              Math.sin(radiansLongitude / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return EARTH_RADIUS_METERS * c;
}

export function latLonSquaredDistanceMeters(fromLatitude: number, fromLongitude: number, toLatitude: number, toLongitude: number): number {
    return latLonDistanceMeters(fromLatitude, fromLongitude, toLatitude, toLongitude) ** 2;
}

export function closestAndFurthestLatitudeFromEquator(lat1: number, lat2: number): [number, number] {
    if (lat1 * lat2 <= 0) {
        return [0.0, Math.max(Math.abs(lat1), Math.abs(lat2))];
    } else {
        const closest = Math.abs(lat1) < Math.abs(lat2) ? lat1 : lat2;
        const furthest = Math.abs(lat1) > Math.abs(lat2) ? lat1 : lat2;
        return [closest, furthest];
    }
}

export function calculateMaxLongitudeLengthRatio(latitudeRangeLower: number, latitudeRangeUpper: number): number {
    const [closest, furthest] = closestAndFurthestLatitudeFromEquator(latitudeRangeLower, latitudeRangeUpper);
    const longestLength = parallelDistanceMeters(1, closest);
    const shortestLength = parallelDistanceMeters(1, furthest);
    return longestLength / shortestLength;
}

export function calculateLongitudeDeltas(longitude0: number, longitude1: number): number {
    const delta = longitude1 - longitude0;
    return delta >= 0 ? delta : 360 + delta;
}

export function calculateLatitudeDeltas(latitude0: number, latitude1: number): number {
    return Math.abs(latitude1 - latitude0);
}

export function lonDeltaFromDirection(bearing: Bearing, distance: Length, atLatitude: number): number {
    const unitSphereDistance = distance.meters / EARTH_CIRCUMFERENCE_METERS;
    const longitudeScale = Math.abs(1 / Math.cos(toRadians(atLatitude)));
    return toDegrees(Math.PI * 2 * longitudeScale * unitSphereDistance * Math.sin(bearing.radians));
}

export function latDeltaFromDirection(bearing: Bearing, distance: Length): number {
    const unitSphereDistance = distance.meters / EARTH_CIRCUMFERENCE_METERS;
    return toDegrees(Math.PI * 2 * unitSphereDistance * Math.cos(bearing.radians));
}

export function latLonDeltaFromDirection(latitude: number, bearing: Bearing, distance: StochasticLength): [number, number] {
    const unitSphereDistance = distance.meters / EARTH_CIRCUMFERENCE_METERS;
    const longitudeScale = Math.abs(1 / Math.cos(toRadians(latitude)));
    const longitudeDelta = toDegrees(Math.PI * 2 * longitudeScale * unitSphereDistance * Math.sin(bearing.radians));
    const latitudeDelta = toDegrees(Math.PI * 2 * unitSphereDistance * Math.cos(bearing.radians));
    return [latitudeDelta, longitudeDelta];
}

export function findMidPoint(lat1: number, long1: number, lat2: number, long2: number): [number, number] {
    const [latRad, lonRad] = findMidPointFromRads(toRadians(lat1), toRadians(long1), toRadians(lat2), toRadians(long2));
    return [toDegrees(latRad), toDegrees(lonRad)];
}

export function findMidPointFromRads(lat1Rad: number, long1Rad: number, lat2Rad: number, long2Rad: number): [number, number] {
    const cosLat2 = Math.cos(lat2Rad);
    const bX = cosLat2 * Math.cos(long2Rad - long1Rad);
    const bY = cosLat2 * Math.sin(long2Rad - long1Rad);
    const cosLat1Bx = Math.cos(lat1Rad) + bX;
    const latitudeRad = Math.atan2(Math.sin(lat1Rad) + Math.sin(lat2Rad),
                                   Math.sqrt(cosLat1Bx ** 2 + bY ** 2));
    const longitudeRad = long1Rad + Math.atan2(bY, cosLat1Bx);
    return [latitudeRad, longitudeRad];
}

export abstract class StochasticGeoPoint {
    abstract get latitude(): number;
    get latitudeRadians(): number {
        return toRadians(this.latitude);
    }

    abstract get longitude(): number;
    get longitudeRadians(): number {
        return toRadians(this.longitude);
    }

    get latLonTuple(): [number, number] {
        return [this.latitude, this.longitude];
    }

    abstract get varianceMeters(): number;

    stochasticDistance(other: StochasticGeoPoint): StochasticLength {
        const distanceMeters = latLonDistanceMeters(this.latitude, this.longitude, other.latitude, other.longitude);
        const variance = this.varianceMeters + other.varianceMeters 
        return stochasticMeters(distanceMeters, variance);
    }

    distance(other: StochasticGeoPoint): Length {
        const distanceMeters = latLonDistanceMeters(this.latitude, this.longitude, other.latitude, other.longitude);
        return meters(distanceMeters);
    }

    offset(latitudeOffset: number, longitudeOffset: number): StochasticGeoPoint {
        return new StochasticLatLonPoint(this.latitude + latitudeOffset, this.longitude + longitudeOffset, this.varianceMeters);
    }

    bearing(to: StochasticGeoPoint): Bearing {
        const lat1 = this.latitudeRadians;
        const long1 = this.longitudeRadians;
        const lat2 = to.latitudeRadians;
        const long2 = to.longitudeRadians;
        
        const y = Math.sin(long2 - long1) * Math.cos(lat2);
        const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(long2 - long1);

        const unmappedRadians = Math.atan2(y, x);
        const mappedRadians = (unmappedRadians + TWO_PI) % TWO_PI;
        return new Bearing(toDegrees(mappedRadians));
    }

    stochasticMidpointTo(other: StochasticGeoPoint): StochasticGeoPoint {
        const [ latitude, longitude ] = findMidPoint(this.latitude, this.longitude, other.latitude, other.longitude);
        const varianceMeters = this.varianceMeters + other.varianceMeters;
        return new StochasticLatLonPoint(latitude, longitude, varianceMeters);
    }
    
    midpointTo(other: GeoPoint): GeoPoint {
        const [ latitude, longitude ] = findMidPoint(this.latitude, this.longitude, other.latitude, other.longitude);
        return new LatLonPoint(latitude, longitude);
    }

    stochasticMoved(bearing: Bearing, distance: StochasticLength): StochasticGeoPoint {
        const [latitudeDelta, longitudeDelta] = latLonDeltaFromDirection(this.latitude, bearing, distance);
        return new StochasticLatLonPoint(
            this.latitude + latitudeDelta,
            this.longitude + longitudeDelta,
            this.varianceMeters + distance.varianceMeters
        );
    }

    moved(bearing: Bearing, distance: Length): StochasticGeoPoint {
        const [latitudeDelta, longitudeDelta] = latLonDeltaFromDirection(this.latitude, bearing, distance);
        const resultLat = this.latitude + latitudeDelta;
        const resultLon = this.longitude + longitudeDelta;
        return new StochasticLatLonPoint(resultLat, resultLon, this.varianceMeters);
    }

    format(separator = ", ", opener = "(", closer = ")"): string {
        return `${opener}${this.latitude.toFixed(7)}${separator}${this.longitude.toFixed(7)}${closer}`;
    }

    formatCSVStyle(): string {
        return this.format("\t", "", "");
    }

    toData(): { latitude: number, longitude: number, varianceMeters?: number } {
        if (this.varianceMeters === 0)
            return { latitude: this.latitude, longitude: this.longitude };
        return { latitude: this.latitude, longitude: this.longitude, varianceMeters: this.varianceMeters};
    }

    static fromData(data: GeoPointData): StochasticGeoPoint {
        if (data.varianceMeters === undefined || areApproximately(data.varianceMeters, 0, 0.1)) {
            return new LatLonPoint(data.latitude, data.longitude);
        } else {
            return new StochasticLatLonPoint(data.latitude, data.longitude, data.varianceMeters);
        }
    }

    toString(): string {
        return `(${this.latitude.toFixed(7)}, ${this.longitude.toFixed(7)})`;
    }
}

export abstract class GeoPoint extends StochasticGeoPoint{
    get varianceMeters(): 0 {
        return 0;
    }

    moved(bearing: Bearing, distance: Length): GeoPoint {
        const [latitudeDelta, longitudeDelta] = latLonDeltaFromDirection(this.latitude, bearing, distance)
        return new LatLonPoint(this.latitude + latitudeDelta, this.longitude + longitudeDelta);
    }

    static fromData(data: GeoPointData): GeoPoint {
        return new LatLonPoint(data.latitude, data.longitude);
    }
}


export class LatLonPoint extends GeoPoint {
    constructor(
        readonly latitude: number,
        readonly longitude: number
    ) {
        super()
    }
}


export class StochasticLatLonPoint extends StochasticGeoPoint {
    constructor(
        readonly latitude: number,
        readonly longitude: number,
        readonly varianceMeters: number
    ) {
        super();
    }
}

export const ONE_LATITUDE_IN_METERS: number = meridianDistanceMeters(1)
export const VIENNA: LatLonPoint = new LatLonPoint(48.20849, 16.37208);
export const STEPHANS_CATHEDRAL_VIENNA: LatLonPoint = new LatLonPoint(48.2084719, 16.3730564);
export const ST_PETERS_CHURCH_VIENNA: LatLonPoint = new LatLonPoint(48.2094111, 16.3699081);
export const LINZ: LatLonPoint = new LatLonPoint(48.300255, 14.286057);
export const LONDON: LatLonPoint = new LatLonPoint(51.509865, -0.118092);
export const NEW_YORK: LatLonPoint = new LatLonPoint(40.730610, -73.935242);
export const QUITO: LatLonPoint = new LatLonPoint(-0.1807, -78.4678);
export const PORT_LOUIS: LatLonPoint = new LatLonPoint(-20.16089120, 57.50122220);

export const VIENNA_LINZ_DISTANCE_METERS: number = 154800;
export const LENGTH_OF_ONE_LATITUDE: Length = new Meters(ONE_LATITUDE_IN_METERS);


export async function getPosition(timeout: number = 10000): Promise<GeoPoint> {
    return getCoordinates(timeout).then(coords => new LatLonPoint(coords.latitude, coords.longitude));
}


export async function getOptionalPosition(timeout: number = 10000): Promise<GeoPoint | undefined> {
    try {
        return await getPosition();
    } catch (error) {
        return undefined;
    }
}


export async function getPositionOr(defaultPosition: GeoPoint, timeout: number = 10000): Promise<GeoPoint> {
    try {
        return await getPosition(timeout);
    } catch (error) {
        return defaultPosition;
    }
}


/**
 * Represents a listener for position updates.
 */
interface PositionListener {
    
    /**
     * Notifies the listener about a new position.
     * 
     * @param position - The new position.
     * @param accuracy - The accuracy of the position.
     * @returns A boolean indicating whether the listener should continue receiving updates.
     */
    onPosition(position: GeoPoint, accuracy: number): boolean;
    
    onError(error: GeolocationPositionError): void;

    /**
     * Called when the listener is registered.
     */
    onRegister(): void;
}


class ContinousPositionListener implements PositionListener {
    constructor(
        private callback: (position: GeoPoint, accuracyMeters: number) => boolean,
        private errorCallback?: (error: GeolocationPositionError) => void,
        readonly minAccuracy?: number
    ) {

    }

    onPosition(position: GeoPoint, accuracy: number): boolean {
        if (this.minAccuracy === undefined || accuracy <= this.minAccuracy) {
            return this.callback(position, accuracy);
        }
        return true;
    }

    onError(error: GeolocationPositionError): void {
        if (this.errorCallback !== undefined) {
            this.errorCallback(error);
        }
    }

    onRegister(): void { }
}

export class PositionWatcher {
    private _lastReadPosition?: GeoPoint
    private _lastReadAccuracy?: number;
    private watchId?: number;
    private positionListeners: PositionListener[] = [];
    private pendingPositionListeners: PositionListener[] = [];
    private _watchStart?: Date;

    private notifyOnErrorListeners(error: GeolocationPositionError) {
        this.positionListeners.forEach(listener => {
            listener.onError(error);
        });
    }

    get lastReadPosition(): GeoPoint | undefined {
        return this._lastReadPosition;
    }

    get lastReadAccuracy(): number | undefined {
        return this._lastReadAccuracy;
    }

    watchWithError(listener: (position: GeoPoint, accuracyMeters: number) => boolean, onError: (error: GeolocationPositionError) => void, minAccuracy?: number) {
        this.pendingPositionListeners.push(new ContinousPositionListener(listener, onError, minAccuracy));
    }

    watch(listener: (position: GeoPoint, accuracyMeters: number) => boolean, minAccuracy?: number) {
        this.pendingPositionListeners.push(new ContinousPositionListener(listener, undefined, minAccuracy));
    }

    notifyOnGetListeners(position: GeoPoint, accuracy: number) {
        this.positionListeners = this.positionListeners.concat(this.pendingPositionListeners)
        this.pendingPositionListeners = []
        this.positionListeners = this.positionListeners.filter(listener => {
            return listener.onPosition(position, accuracy);
        });
    }

    get watchStart(): Date | undefined {
        return this._watchStart;
    }

    start() {
        if (this.watchId !== undefined) {
            this.stop()
        }
        this._watchStart = new Date()
        this.watchId = navigator.geolocation.watchPosition(
            position => this.onNativeReceive(position),
            error => this.onReceiveError(error),
            {
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: 10000,
            }
        );
    }

    stop() {
        if (this.watchId !== undefined) {
            navigator.geolocation.clearWatch(this.watchId);
            this.watchId = undefined;
        } else {
            throw new Error("Can't stop watching position because currently not watching it");
        }
    }

    private onNativeReceive(position: GeolocationPosition) {
        const { latitude, longitude, accuracy } = position.coords;
        this.forceReceive(new LatLonPoint(latitude, longitude), accuracy);
    }

    forceReceive(position: GeoPoint, accuracy: number) {
        this._lastReadPosition = position;
        this._lastReadAccuracy = accuracy;
        this.notifyOnGetListeners(position, accuracy);
    }

    private onReceiveError(error: GeolocationPositionError) {
        this.notifyOnErrorListeners(error)
    }

    static create(): PositionWatcher {
        if (isDebug()) {
            return new DebugPositionWatcher();
        }
        return new PositionWatcher();
    }
}

export class WatcherDialog {
    private onPosition?: (posistion: GeoPoint, accuracyMeters: number) => boolean;
    private minAccuracyMeters?: number;

    constructor(
            readonly popup: Popup, 
            readonly watcher: PositionWatcher) {

    }

    start(onFirstPosition: (posistion: GeoPoint, accuracyMeters: number) => boolean, minAccuracyMeters: number): void {
        this.popup.message = 'Getting position, please wait...'
        this.popup.show();
        this.watcher.watchWithError(
            (position: GeoPoint, accuracyMeters: number) => {
                if (accuracyMeters > accuracyMeters) {
                    this.popup.message = `Position accuracy is ${accuracyMeters.toFixed(0)} meters. Please wait for better accuracy.`;
                    this.popup.isClosable = false;
                    this.popup.show()
                    return true
                }
                this.popup.close()
                if (onFirstPosition(position, accuracyMeters)) {
                    this.watcher.watch(
                        (position: GeoPoint, accuracyMeters: number) => onFirstPosition(position, accuracyMeters),
                        this.minAccuracyMeters
                    )
                }

                return false
            },
            (error: GeolocationPositionError) => this.initializingError(error)
        )
        this.watcher.start();
    }
    
    initializingError(error: GeolocationPositionError) {
        if (this.popup) {
            this.popup.message = `Failed to get position: ${error.message}`;
            this.popup.isClosable = true;
            this.popup.show()
        }
    }
}


export class DebugPositionWatcher extends PositionWatcher {
    private startAccuracy = 1000;
    private minAccuracyMeters = 2;
    private accuracyFactor = 1;
    private offsets: { [key: number]: Length } = {};
    constructor() {
        super();
        CARDINAL_BEARINGS.forEach(bearing => {
            this.offsets[bearing.degrees] = new Meters(0);
        });
    }

    private get accuracyMetersOverwrite(): number {
        return Math.max(this.startAccuracy * this.accuracyFactor, this.minAccuracyMeters);
    }

    private reduceAccuracy() {
        this.accuracyFactor *= 0.5;
        if (this.accuracyMetersOverwrite > this.minAccuracyMeters) {
            setTimeout(() => this.reduceAccuracy(), 200);
            this.notifyLastPosition();
        }
    }

    start(): void {
        super.start();
        this.reduceAccuracy();
    }

    addOffset(direction: Bearing, delta: Length) {
        this.offsets[direction.degrees] = this.offsets[direction.degrees].added(delta);
        this.notifyLastPosition();
    }

    getOffset(direction: Bearing): Length {
        return this.offsets[direction.degrees];
    }

    get oneSecondPassed(): boolean {
        return this.watchStart !== undefined && new Date().getTime() - this.watchStart.getTime() > 1000;
    }

    private notifyLastPosition() {
        if (this.lastReadPosition !== undefined) {
            this.notifyOnGetListeners(this.lastReadPosition, this.accuracyMetersOverwrite);
        } else if (this.oneSecondPassed) {
            this.forceReceive(VIENNA, this.accuracyMetersOverwrite)
        }
    }

    notifyOnGetListeners(position: GeoPoint, accuracy: number) {
        CARDINAL_BEARINGS.forEach(bearing => {
            position = position.moved(bearing, this.getOffset(bearing));
        });
        super.notifyOnGetListeners(position, Math.min(accuracy, this.accuracyMetersOverwrite))
    }
}