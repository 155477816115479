import { Page } from "./Page"
import { WorldSummary, fetchMyWorldSummaries, fetchOtherWorldSummaries } from "./WorldSummary";


class Index extends Page {
    createGameButton: HTMLElement;
    gamesContainer: HTMLElement;
    adminGamesContainer: HTMLElement;

    constructor() {
        super();

        this.createGameButton = document.getElementById('createGameButton') as HTMLElement
        this.gamesContainer = document.getElementById('myGamesContainer') as HTMLElement
        this.adminGamesContainer = document.getElementById('adminGamesContainer') as HTMLElement
    }

    async loadGames() {
        const user = this.sessionUser;
        if (user === undefined) {
            document.getElementById('welcomeUnknownSection')!.style.display = 'block';
            return;
        }
        document.getElementById('welcomeSection')!.style.display = 'block';
        this.createGameButton.style.display = 'block';
        this.createGameButton.addEventListener('click', () => {
            window.location.href = '/create_game/deathmatch';
        });
        try {
            await this.loadWorldSummaries(fetchMyWorldSummaries, this.gamesContainer);
            if (user.isAdmin) {
                await this.loadWorldSummaries(fetchOtherWorldSummaries, this.adminGamesContainer);
            }
        } catch (error) {
            alert('Failed to load games: ' + error);
        }
    }

    async loadWorldSummaries(fetcher: () => Promise<WorldSummary[]>, container: HTMLElement) {
        const worldSummaries = await fetcher();
        container.parentElement!.style.display = 'block';
        if (worldSummaries.length === 0) {
            container.innerHTML = 'No running games';
            return;
        }
        container.appendChild(WorldSummary.tableOf(worldSummaries, this.sessionUser));
    }
}

document.addEventListener('DOMContentLoaded', async function() {
    const indexPage = new Index();
    await indexPage.loadGames();
});