

export class DataConverter<T> {
    readonly converted: {[objectId: string]: T} = {}

    readonly overrideFactories: {[objectId: string]: (data: any) => T} = {}
    readonly onCreateListeners: ((object: T, data: any) => void)[] = []
    readonly onUpdateListeners: ((object: T, data: any) => void)[] = []
    readonly onRemoveListeners: ((object: T) => void)[] = []
    constructor(
        readonly defaultFactory: (data: any) => T
    ) {

    }

    update(data: { [webId: string]: any }) {
        for (const webId in this.converted) {
            if (!(webId in data)) {
                const object = this.converted[webId]
                delete this.converted[webId];
                for (const listener of this.onRemoveListeners) {
                    listener(object);
                }
            }
        }

        for (const webId in data) {
            const objectData = data[webId];
            if (webId in this.converted) {
                const object = this.converted[webId]
                for (const listener of this.onUpdateListeners) {
                    listener(object, objectData);
                }
            } else {
                const object = this.createObject(objectData)
                for (const listener of this.onCreateListeners) {
                    listener(object, objectData);
                }
            }
        }
    }

    private createObject(data: any): T {
        const webId = data["web_id"]
        let factory: (data: any) => T
        if (webId in this.overrideFactories) {
            factory = this.overrideFactories[webId]
        } else {
            factory = this.defaultFactory
        }
        
        const object = factory(data);
        this.converted[webId] = object;
        return object
    }
}