import { ActionType } from "./Actions";
import { EffectType } from "./Effects";
import { EuclideanRectangle } from "./GeoShape";
import { User } from "./User.js";
import { getJson, formatTime, formatDateTime } from "./Utils";


type MappedData = { [key: string]: any };


export class WorldSummary {
    constructor(
        readonly name: string,
        readonly state: string,
        readonly owner: string,
        readonly playingUsers: string[],
        readonly pendingUsers: string[],
        readonly bounds: EuclideanRectangle,
        readonly actionTypes: ActionType[],
        readonly effectTypes: EffectType[],
        readonly creationDate: Date
    ) {

    }

    get allUsers(): string[] {
        return this.playingUsers.concat(this.pendingUsers);
    }

    static fromData(data: MappedData): WorldSummary {
        const actionTypesAsData = Object.values(data["action_types"])
        const actionTypes = actionTypesAsData.map((data: any) => ActionType.fromData(data));
        const effectTypesAsData = Object.values(data["effect_types"])
        const effectTypes = effectTypesAsData.map((data: any) => EffectType.fromData(data));
        const creationDateString = data["creation_date"];
        return new WorldSummary(
            data["name"],
            data["state"],
            data["owner"],
            data["playing_users"],
            data["pending_users"],
            EuclideanRectangle.fromData(data["bounds"]),
            actionTypes,
            effectTypes,
            new Date(creationDateString)
        );
    }

    static tableHeader(): HTMLTableRowElement {
        const row = document.createElement('tr');
        row.innerHTML = `
            <th>Name</th>
            <th>State</th>
            <th>Owner</th>
            <th>Users</th>
            <th>Created</th>
            <th>...</th>
        `;
        return row;
    }

    toTableRow(forUser?: User): HTMLTableRowElement {
        const row = document.createElement('tr');
        let deleteHtml: string
        if (forUser !== undefined && (forUser.username === this.owner || forUser.isAdmin)) {
            deleteHtml = `<a class="delete-game-btn" href="/${this.name}/destroy">X</a>`
        } else {
            deleteHtml = ''
        }
        row.innerHTML = `
            <td><a href="/${this.name}" class="game-link">${this.name}</a></td>
            <td>${this.state}</td>
            <td>${this.owner}</td>
            <td>${this.playingUsers.length}/${this.allUsers.length}</td>
            <td>${formatDateTime(this.creationDate, true)}</td>
            <td>${deleteHtml}</td>
        `;
        return row;
    }

    static tableOf(worldSummaries: WorldSummary[], forUser?: User): HTMLTableElement {
        const table = document.createElement('table');
        table.appendChild(WorldSummary.tableHeader());
        worldSummaries.forEach(worldSummary => table.appendChild(worldSummary.toTableRow(forUser)));
        return table;
    }
}

export async function fetchWorldSummary(worldName: string): Promise<WorldSummary> {
    return await getJson(`/${worldName}/summary`).then((data: any) => WorldSummary.fromData(data));
}

export async function fetchMyWorldSummaries(): Promise<WorldSummary[]> {
    return await getJson("/my_games/data").then((data: any) => Object.values(data).map((summaryData: any) => WorldSummary.fromData(summaryData)));
}

export async function fetchOtherWorldSummaries(): Promise<WorldSummary[]> {
    return await getJson("/other_games/data").then((data: any) => Object.values(data).map((summaryData: any) => WorldSummary.fromData(summaryData)));
}