

export class Color {
    private _red: number;
    private _green: number;
    private _blue: number;

    constructor(red: number, green: number, blue: number) {
        this._red = red;
        this._green = green;
        this._blue = blue;
    }

    get red(): number {
        return this._red;
    }

    get green(): number {
        return this._green;
    }

    get blue(): number {
        return this._blue;
    }

    toCssStyle(): string {
        return `rgb(${this._red}, ${this._green}, ${this._blue})`;
    }

    interpolate(other: Color, t: number): Color {
        return new Color(
            this._red + (other._red - this._red) * t,
            this._green + (other._green - this._green) * t,
            this._blue + (other._blue - this._blue) * t
        );
    }
}

export const RED = new Color(255, 0, 0);
export const GREEN = new Color(0, 255, 0);
export const BLUE = new Color(0, 0, 255);
export const YELLOW = new Color(255, 255, 0);
export const CYAN = new Color(0, 255, 255);
export const MAGENTA = new Color(255, 0, 255);
export const WHITE = new Color(255, 255, 255);
export const BLACK = new Color(0, 0, 0);
export const GRAY = new Color(128, 128, 128);
export const LIGHT_GRAY = new Color(192, 192, 192);
export const DARK_GRAY = new Color(64, 64, 64);

export const PURPLE = new Color(128, 0, 128);

