import { Bearing } from "./Bearing";
import { ALL_TYPES, Type, parseType } from "./Types";



export class ValueValidityError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ValueValidityError';
    }
}

export class ValueValidityTypeError extends ValueValidityError {
    constructor(value: any, expectedType: Type<any>) {
        super(`Invalid type: ${value} is not of type ${expectedType.serverIdentifier}`);
        this.name = 'ValueValidityTypeError';
    }
}

export abstract class ParameterSpecification<T> {
    abstract assertValidity(value: any): void;

    isValid(value: any): boolean {
        try {
            this.assertValidity(value);
            return true;
        } catch (e) {
            if (e instanceof ValueValidityError) {
                return false;
            }
            throw e;
        }
    }
}

export class TypeSpecification<T> extends ParameterSpecification<T> {
    expectedType: Type<T>;

    constructor(expectedType: Type<T>) {
        super();
        this.expectedType = expectedType;
    }

    assertValidity(value: any): value is T {
        if (this.expectedType.isInstance(value)) {
            return true
        }
        throw new ValueValidityTypeError(value, this.expectedType);
    }

    isValid(value: any): value is T {
        try {
            this.assertValidity(value);
            return true;
        } catch (e) {
            if (e instanceof ValueValidityError) {
                return false;
            }
            throw e;
        }
    }
}

export class Parameter<T> {
    name: string;
    typeRequirement: TypeSpecification<T>;
    specifications: ParameterSpecification<T>[];

    constructor(name: string, expectedType: Type<any>) {
        if (!expectedType) {
            throw new Error("Expected super type cannot be None.");
        }
        this.name = name;
        this.typeRequirement = new TypeSpecification(expectedType);
        this.specifications = [this.typeRequirement];
    }

    get expectedType(): Type<any> {
        return this.typeRequirement.expectedType;
    }

    toData(): {name: string, type: string} {
        return {
            name: this.name,
            type: this.typeRequirement.expectedType.serverIdentifier
        };
    }

    static fromData(data: Record<string, any>): Parameter<any> {
        const name = data["name"];
        const parsedType = parseType(data["type"]);
        return new Parameter(name, parsedType);
    }

    assertValidity(value: any): value is T {
        for (const requirement of this.specifications) {
            requirement.assertValidity(value);
        }
        return true;
    }

    isValid(input: any): input is T {
        try {
            this.assertValidity(input);
            return true;
        } catch (e) {
            if (e instanceof ValueValidityError) {
                return false;
            }
            throw e;
        }
    }

    toString(): string {
        return `Parameter(${this.name}, ${this.typeRequirement.expectedType.serverIdentifier})`;
    }

    asAssigned(value: any): ParameterAssignment<T> {
        this.assertValidity(value);
        return new ParameterAssignment(this.name, this.typeRequirement.expectedType, value);
    }
}

type ElementaryTypes = string | number | boolean;


function isElementaryType(toCheck: any): toCheck is ElementaryTypes {
    for (let type of ALL_TYPES) {
        if (type.isInstance(type)) {
            return true;
        }
    }
    return false;
}

export class ParameterAssignment<T> {
    name: string;
    expectedType: Type<T>;
    value: T;

    constructor(name: string, expectedType: Type<T>, value: T) {
        this.name = name;
        this.expectedType = expectedType;
        this.value = value;
    }

    toData(): {name: string, type: string, value: any} {
        return {
            name: this.name,
            type: this.expectedType.serverIdentifier,
            value: this.expectedType.toData(this.value)
        };
    }

    static fromData(data: any): ParameterAssignment<any> {
        const typeStr = data.type;
        const parsedType: Type<any> = parseType(typeStr);
 
        const parsed = parsedType.fromData(data.value)
        return new ParameterAssignment(data.name, parsedType, parsed);
    }

    toString(): string {
        return `ParameterAssignment(name=${this.name}, expectedType=${this.expectedType.serverIdentifier}, value=${this.value})`;
    }
}
